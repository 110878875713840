import Router from 'next/router';
import React, { useEffect } from 'react';
import { Loading } from '@jux/ui/components';
import { Segment } from '@jux/ui/tools/Segment';
import { api } from '@jux/ui/trpc/client/api';
import { useAnalytics } from '@jux/ui/hooks';
import { PATHS } from '../../utils/paths';
import { HOC } from '../HOC.interface';
export const withOnboardingLayout: HOC = (Component: React.ElementType) => (props: any) => {
  // Don't let user into the dashboard unless he has created an organizations
  const organizationsQuery = api.user.getUserOrganizations.useQuery();
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.pageLoadedEvent('onboarding', window.location.pathname);
  }, [analytics]);
  if (organizationsQuery.isLoading) {
    return <Loading />;
  }
  if (organizationsQuery.isError) {
    throw new Error(`Error: ${organizationsQuery.error.message}`);
  }
  if (organizationsQuery.data.length !== 0) {
    Router.push(PATHS.MAIN_PAGE);
    return null;
  }
  return <>
        <Segment />
        <Component {...props} />
      </>;
};