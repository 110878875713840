import { FC, useEffect } from 'react';
import { useLoadFonts } from '@jux/dui';
import { api } from '@jux/ui/trpc/client/api';
export const FontsLoader: FC = () => {
  const {
    data: supportedFonts
  } = api.fonts.getSupportedFonts.useQuery();
  const {
    loadFonts
  } = useLoadFonts(supportedFonts);
  useEffect(() => {
    if (!window) return;
    loadFonts();
  }, [loadFonts]);
  return null;
};