import { ComponentProps, ComponentType, FC, PropsWithChildren } from 'react';
import Router from 'next/router';
import { Loading } from '@jux/ui/components';
import { PATHS } from '@jux/ui/utils/paths';
import { useOrganizations } from '@jux/ui/hooks';
import { AuthRequiredTools } from '@jux/ui/tools';
import { HOC } from '../HOC.interface';
const OrganizationAuthGuard: FC<PropsWithChildren> = ({
  children
}) => {
  const {
    userOrganizations,
    isLoading
  } = useOrganizations();
  if (isLoading || !userOrganizations) {
    return <Loading />;
  }
  if (userOrganizations.length === 0) {
    Router.push(PATHS.WELCOME);
    return null;
  }

  // we need to wrap it with a fragment because it's not allowed for children to be a ReactNode
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>
      <AuthRequiredTools data-sentry-element="AuthRequiredTools" data-sentry-source-file="withOrgAuthGuard.tsx" />
      {children}
    </>;
};
export const withOrgAuthGuard: HOC = <C extends ComponentType<any>, P extends ComponentProps<C>>(Component: C) => (props: P) => <OrganizationAuthGuard>
        <Component {...props} />
      </OrganizationAuthGuard>;